import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Footer.scss';
import facebook from '../../Images/facebook.png'
import instagram from '../../Images/instagram.png'
import linkedin from '../../Images/linkedin.png'
import twitter from '../../Images/twitter.png'
import threads from '../../Images/threads.png'
import Logo from '../../Images/Logo.png'

// import { useLocation } from "react-router-dom";

export default function Footer({ WebSitePages, navigateTo }) {
    const Navigate = useNavigate()
    return (
        <div className="Footer">
            <div className="Footer2">
                <div className="Footer2Logo" onClick={() => navigateTo("")}>
                    <h1><b>Torrance</b> Chimney Sweep</h1>
                </div>
            </div>
            <div className="FooterTop">

                <div className="SiteMapDiv">
                    <h1>Torrance Chimney Sweep</h1>
                    <div className="SiteMapDivList">
                        <a href="/">Home Page</a>
                        <a href="/book">Book Chimney Sweep Appointment Online In Torrance, California</a>
                        <a href="/contact">Contact Us</a>
                        <a href="/blog">Blog</a>
                        <a href="/faq">Faq</a>
                        <a href="/sitemap">Site Map</a>
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>Services</h1>
                    <div className="SiteMapDivList">
                        <a href="/service/Chimney-Cap-Repair" className="Header1BoxMenuBox">
                            Chimney Cap Repair in Torrance, California
                        </a>
                        <a href="/service/Chimney-Cleaning" className="Header1BoxMenuBox">
                            Chimney Cleaning in Torrance, California
                        </a>
                        <a href="/service/Chimney-Construction" className="Header1BoxMenuBox">
                            Chimney Construction in Torrance, California
                        </a>
                        <a href="/service/Chimney-Crown-Repair" className="Header1BoxMenuBox">
                            Chimney Crown Repair in Torrance, California
                        </a>
                        <a href="/service/Chimney-Flue-Installation" className="Header1BoxMenuBox">
                            Chimney Flue Installation in Torrance, California
                        </a>
                        <a href="/service/Chimney-Flue-Repair" className="Header1BoxMenuBox">
                            Chimney Flue Repair in Torrance, California
                        </a>
                        <a href="/service/Chimney-Inspection" className="Header1BoxMenuBox">
                            Chimney Inspection in Torrance, California
                        </a>
                        <a href="/service/Chimney-Installation" className="Header1BoxMenuBox">
                            Chimney Installation in Torrance, California
                        </a>
                        <a href="/service/Chimney-Insulation" className="Header1BoxMenuBox">
                            Chimney Insulation in Torrance, California
                        </a>
                        <a href="/service/Chimney-Cracks-Repair" className="Header1BoxMenuBox">
                            Chimney Cracks Repair in Torrance, California
                        </a>
                        <a href="/service/Chimney-Firebox-Paint" className="Header1BoxMenuBox">
                            Chimney Firebox Paint in Torrance, California
                        </a>
                        <a href="/service/Chimney-Firebox-Cleaining" className="Header1BoxMenuBox">
                            Chimney Firebox Cleaining in Torrance, California
                        </a>
                        <a href="/service/Chimney-Firebox-Inspection" className="Header1BoxMenuBox">
                            Chimney Firebox Inspection in Torrance, California
                        </a>
                        <a href="/service/Chimney-Best-Company" className="Header1BoxMenuBox">
                            Chimney Best Company in Torrance, California
                        </a>
                        <a href="/service/Firebox-Inspection" className="Header1BoxMenuBox">
                            Firebox Inspection in Torrance, California
                        </a>
                        <a href="/service/Firebox-Sweeping" className="Header1BoxMenuBox">
                            Firebox Sweeping in Torrance, California
                        </a>
                        <a href="/service/Fireplace-Inspection" className="Header1BoxMenuBox">
                            Fireplace Inspection in Torrance, California
                        </a>
                        <a href="/service/Firepalce-Repair" className="Header1BoxMenuBox">
                            Firepalce Repair in Torrance, California
                        </a>
                        <a href="/service/Chimney-Liner-Repair" className="Header1BoxMenuBox">
                            Chimney Liner Repair in Torrance, California
                        </a>
                        <a href="/service/Chimney-Maintenance" className="Header1BoxMenuBox">
                            Chimney Maintenance in Torrance, California
                        </a>
                        <a href="/service/Chimney-Pointing" className="Header1BoxMenuBox">
                            Chimney Pointing in Torrance, California
                        </a>
                        <a href="/service/Chimney-Rain-Cap-Installation" className="Header1BoxMenuBox">
                            Chimney Rain Cap Installation in Torrance, California
                        </a>
                        <a href="/service/Chimney-Repair" className="Header1BoxMenuBox">
                            Chimney Repair in Torrance, California
                        </a>
                        <a href="/service/Chimney-Restoration" className="Header1BoxMenuBox">
                            Chimney Restoration in Torrance, California
                        </a>
                        <a href="/service/Chimney-Flashing-Repair" className="Header1BoxMenuBox">
                            Chimney Flashing Repair in Torrance, California
                        </a>
                        <a href="/service/Chimney-Liner-Installation" className="Header1BoxMenuBox">
                            Chimney Liner Installation in Torrance, California
                        </a>
                        <a href="/service/Chimney-Damper-Repair" className="Header1BoxMenuBox">
                            Chimney Damper Repair in Torrance, California
                        </a>
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>Contact Us</h1>
                    <div className="SiteMapDivList">
                        <a target='_blank' href="">
                            Torrance, California
                        </a>
                        <a target='_blank' href="tel:424-260-9781">
                            424-260-9781
                        </a>
                        <a target='_blank' href="mailto:info@Torrancehimneysweep.org">
                            info@Torrancehimneysweep.org
                        </a>
                    </div>
                </div>

            </div>
            <div className="Footer3">
                <h1>Copyright © 2023 Torrance Chimney Sweep. All rights reserved.</h1>
            </div>
        </div>
    )
}
